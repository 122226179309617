
<template>
  <v-container class="px-12 py-0">
    <img class="ipog" src="@/assets/ipog.png" alt="logo" />
    <img class="effect1" src="@/assets/effect1.png" alt="effect1" />
    <img class="effect2" src="@/assets/effect2.png" alt="effect2" />
    <v-row justify="center" align="center" class="home-box d-none d-md-flex">
      <v-col v-if="step == 1" cols="6" class="px-12">
        <v-row>
          <!-- <v-col cols="12">
            <h1 class="ipog-title font-weight-light">IPOG TIMER.</h1>
          </v-col> -->
          <v-col cols="12">
            <v-select v-model="timerType" :items="timerTypes" item-text="name" label="SELECIONE UMA IMAGEM PARA APRESENTAR" return-object dark outlined/>
          </v-col>
          <v-col cols="4">
            <v-select v-model="timeHour" :items="hours" label="HORA" dark outlined/>
          </v-col>
          <v-col cols="4">
            <v-select v-model="timeMinute" :items="minutes" label="MINUTO" dark outlined/>
          </v-col>
          <v-col cols="4">
            <v-select v-model="timeSecond" :items="seconds" label="SEGUNDO" dark outlined/>
          </v-col>
          <v-col cols="12 mb-6">
            <v-btn class="create-button" color="white" @click="setTimer()" large outlined>CRIAR TEMPORIZADOR</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn class="create-button" color="white" @click="step = 4" large outlined>BOAS-VINDAS</v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn class="create-button" color="white" :disabled="timerType.image == 1" @click="step = 5" large outlined>TEMPO INDEFINIDO</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="create-button" color="white" @click="helpDialog = true" large outlined>AJUDA</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="step == 2" cols="6">
        <v-row class="px-16">
          <v-col cols="12" class="step2-box py-12">
            <h1 class="font-weight-light text-center">TEMPO: {{ timeHour }}:{{ timeMinute }}:{{ timeSecond }}</h1>
            <h2 class="font-weight-light text-center mt-4 mb-6">TIPO: {{ timerType.name }}</h2>
            <div class="d-flex justify-center">
              <v-btn class="mr-1" color="warning" @click="stopTimer(); step = 1;" large>VOLTAR</v-btn>
              <v-btn class="ml-1" color="success" @click="startTimer()" large>INICIAR</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="step == 3" cols="6" class="px-12">
        <v-btn v-if="step == 3" class="cancel-button" @click="stopTimer(); step = 1;" color="dark" large rounded>CANCELAR TEMPO!</v-btn>
        <v-row class="timer-count-box">
          <v-col cols="12">
            <template>
              <h1 v-if="!ended" class="font-weight-light text-center timer-count">{{ duration }}</h1>
              <h1 v-else class="font-weight-light text-center timer-ended">TEMPO FINALIZADO!</h1>
            </template>
            <template v-if="timerType.image != 1">
              <hr>
              <p class="counter-type text-center mt-6">{{ timerType.name }}</p>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="step == 4" cols="12">
        <v-btn class="cancel-button" @click="step = 1;" color="dark" large rounded>VOLTAR!</v-btn>
        <v-row>
          <img class="image-box" :src="require(`@/assets/welcome.png`)" alt="welcome">
        </v-row>
      </v-col>
      <v-col v-if="step == 5" cols="6">
        <v-btn class="cancel-button" @click="step = 1;" color="dark" large rounded>VOLTAR!</v-btn>
        <v-row>
          <img class="image-box" :src="require(`@/assets/${timerType.text}.png`)" alt="study">
        </v-row>
      </v-col>
      <v-col v-if="step != 4" cols="6">
        <img class="image-box" :src="require(`@/assets/${timerType.url}.jpg`)" alt="study">
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="home-box d-md-none">
      <img class="error-image" src="@/assets/errorMessage.png" alt="error">
    </v-row>

    <audio id="alarm">
      <source src="@/assets/alarmSound2.mp3" type="audio/mp3">
    </audio>

    <v-dialog
      v-model="helpDialog" width="700">
      <v-card>
        <v-card-title class="headline grey lighten-2">CENTRO DE AJUDA</v-card-title>
        <v-card-text>
          <p class="mt-4">Obrigado por usar nossa solução! Confira abaixo as instruções de uso das ferramentas...</p>
          <ul class="help-list">
            <li><strong>TEMPORIZADOR</strong> - Basta selecionar a imagem desejada no seletor, há diversas opções que representam cada momento com uma imagem e/ou texto. Abaixo, temos os seletores de hora, minuto e segundo. Definidos os itens acima, basta clicar em "CRIAR TEMPORIZADOR". Uma janela mostrando os detalhes será exibida com as opções de voltar ou iniciar a contagem de tempo. Após iniciado o contador, um botão de cancelamento fica disponivel na parte inferior direita da tela.</li>
            <li><strong>TEMPO INDEFINIDO</strong> - Você pode selecionar uma imagem e exibi-la sem o contador de tempo, basta seleciona-la e clicar no botão "TEMPO INDEFINIDO" na parte inferior da tela. Está função somente é liberada para imagens que não sejam a "PADRÃO"! Esta tela é estática, quando clicada a função abre a mensagem e disponibiliza um botão na parte inferior direita da tela para cancelar/retornar a tela de inicio</li>
            <li><strong>BOAS-VINDAS</strong> - A tela de boas-vindas é estática, quando clicada a função abre a mensagem e disponibiliza um botão na parte inferior direita da tela para cancelar/retornar a tela de inicio.</li>
            <li><strong>DÚVIDAS E/OU SUGESTÕES</strong> - Acredito que o FEEDBACK dos usuários é indispensavel para que as melhorias sejam trabalhadas! Os mesmos podem ser encaminhados para o e-mail "karreira@miolocat.com" ou WhatsApp "+55 011 959 655 865".</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="helpDialog = false">FECHAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import timer from 'moment-timer'
import duration from 'moment-duration-format'

export default {
  name: 'Home',
  data() {
    return {
      time: 0,
      timer: undefined,
      duration: 0,
      durationCheck: undefined,
      helpDialog: false,
      ended: false,
      timerType: { name: 'PADRÃO', image: '1', url: 'study' },
      timerTypes: [
        { name: 'PADRÃO', image: '1', url: 'study' },
        { name: 'PAUSA DE CAFÉ', image: '2', url: 'coffee', text: 'coffeeText' },
        { name: 'PAUSA DE ALMOÇO', image: '3', url: 'lunch', text: 'lunchText' },
        { name: 'SALAS SIMULTANEAS', image: '4', url: 'rooms', text: 'roomsText' },
        { name: 'ATIVIDADE EM SALA', image: '5', url: 'rooms2', text: 'rooms2Text' },
      ],
      timeHour: '00',
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      timeMinute: '00',
      minutes: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
      timeSecond: '00',
      seconds: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
      step: 1
    }
  },
  methods: {
    setTimer() {
      this.ended = false
      this.time = moment.duration(`${this.timeHour}:${this.timeMinute}:${this.timeSecond}`).asMilliseconds()
      this.timer = new moment.duration(this.time).timer({ start: false }, () => {
        if (!this.ended) {
          this.stopTimer()
          document.getElementById('alarm').play()
          this.ended = true
        }
      })
      this.step = 2
    },
    startTimer() {
      this.timer.start()
      this.setCheck()
      this.step = 3
    },
    stopTimer() {
      clearInterval(this.durationCheck)
      this.duration = 0
      this.timer.stop()
      this.ended = true
      document.getElementById('alarm').pause()
      document.getElementById('alarm').currentTime = 0
    },
    setCheck() {
      // let time = this.timer.getRemainingDuration()
      this.durationCheck = setInterval(() => {
        this.duration = moment.duration(this.timer.getRemainingDuration(), "milliseconds").format('HH:mm:ss')
      }, 500)
    }
  },
}
</script>

<style>
  .ipog {
    position: absolute;
    top: 30px;
    left: 30px;
  }
  .home-box {
    height: 100vh;
  }
  .image-box {
    width: 100%;
  }
  .ipog-title {
    color: white;
    font-size: 3rem;
  }
  .create-button {
    width: 100%;
  }
  .step2-box {
    color: white;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 20px;
  }
  .timer-count-box {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, .4);
  }
  .timer-count {
    color: white;
    font-size: 6rem;
  }
  .counter-type {
    color: white;
    font-size: 2rem;
  }
  .cancel-button {
    position: absolute !important;
    right: 30px !important;
    bottom: 30px !important;
  }
  .effect1 {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100px;
  }
  .effect2 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100px;
  }
  .error-image {
    width: 80%;
  }
  .help-list li {
    margin-bottom: 12px;
  }
  .timer-ended {
    color: white;
    font-size: 3rem;
  }
</style>
